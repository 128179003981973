html{
    min-height:100%;
    margin:0;
    padding:0;

}
body {
    min-height:100%;
    margin:0;
    padding:0;
    background: linear-gradient(142deg, rgba(112,80,239,1) 0%, rgba(98,63,238,1) 22%, rgba(84,46,232,1) 51%, rgba(98,63,238,1) 85%, rgba(123,92,246,1) 100%); 
    background-repeat: no-repeat;
    
  }
.container{
    padding:0px 230px;

}
#blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(100px);
    z-index: -999999;
}

#blob {
    position: fixed;
    background: linear-gradient(145deg, rgb(220, 105, 255) 0%, rgba(43, 110, 226, 0.95) 100%);
    height: 300px;
    aspect-ratio: 1;
    z-index: -1000000;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    animation: rotate 5s infinite;
}

@keyframes rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@media (max-width: 1350px) {
    .container {
        padding:0px 100px;
    }
}
@media (max-width: 700px) {
    .container {
        padding:0px 40px;
        
    }
}
@media (max-width: 400px) {
    .container {
        padding:0px 20px;
    }
}

.reveal {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .reveal.active {
    opacity: 1;
    transform: translateY(0);
  }
  