@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Jaro:opsz@6..72&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');


.projects-container{
    padding-top:80px;
    padding-bottom:80px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:30px;
}
.project{
    font-family:"Lexend";
    position:relative;
    background-color: rgba(163, 161, 255, 0.2);
    border-radius: 21px;
    padding:20px;
    transition:0.2s;
}
.project:hover{
    transform:scale(1.05);
}
.project>p{
    font-size:20px;
    color:white;
    font-weight: bold;
}
.project > button {
    position: absolute;
    font-family:"Lexend";
    top: -10px;
    right: -10px;
    background-color: #836AF4;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
    display: block; 
    pointer-events: none;
}
.project:hover > button {
    opacity: 1; 
    pointer-events: auto; 
}
.project:hover{
    box-shadow:4px 4px 10px rgba(74, 74, 74, 0.25);
}
.skills-project{
    margin-top:10px;
    display: flex;
    gap:10px;
    flex-wrap:wrap;

}
.skills-project>button{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: #836AF4;
    border:none;
    padding:10px 15px;
    font-family:"Anta";
    font-weight: 600;
    font-size:12px;
    color:#dddaeb;
    cursor:pointer;
    transition:0.15s;
}
.skills-project>button:hover{
    background-color: #775eeb;

}
.skills-project > div img {
    height: 100%; 
    width: 100%; 
    object-fit: cover;
    /* border-radius: 100px;  */
}
@media (max-width:1030px){
    .projects-container{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width:700px){
    .projects-container{
        grid-template-columns: 1fr;
    }
}